import React from "react";

const style = {
    bucket: {
        paddingLeft: 16,
        paddingTop: 22,
        width: 175,
        display: 'inline-block'
    },
    button: {
        width: 170,
        height: 38,
        backgroundColor: '#71CC45',
        color: 'white',
        fontSize: 16,
        fontFamily: 'Inter',
        border: 'none',
        borderRadius: 5
    },
    bucketImg: {
        width: 170,
        height: 227,
        overflow: "hidden",
        borderRadius: 5
    },
    buttonCont: {
        paddingTop: 15
    },
    bucketLogo: {
        paddingBottom: 10
    }
}

export default function Bucket({props}) {
    return (
        <div className={'bucket'} style={style.bucket}>
            <div className={'bucketLogo'} style={style.bucketLogo}>
                <img src={props.logo} alt={'logo'} height={35} width={170}/>
            </div>
            <div className={'bucketImg'} style={style.bucketImg}>
                <img src={props.imgSrc} alt={'img'} width={170} height={227}/>
            </div>
            <div className={'buttonCont'} style={style.buttonCont}>
                <a href={props.buttonLink}>
                    <button className={'order'} style={style.button} >{props.buttonName}</button>
                </a>
            </div>
        </div>
    )
}