import React from "react";
import Bucket from "./components/Bucket";
import potolok from './potolok.jpg';
import carniz from './carniz.jpg';
import logoP from './logop.svg';
import logoC from './logomo.svg';

function App() {
    return (
        <div className="App">
            <div>
                <Bucket props={{title: 'potolki', imgSrc: potolok, buttonName: 'Потолки', buttonLink: 'https://mrqz.me/60a6356c78f4c800498123eb', logo: logoP}}/>
                <Bucket props={{title: 'carniz', imgSrc: carniz, buttonName: 'Электрокарнизы', buttonLink: 'https://mrqz.me/64f657dd64beda0025c00efe', logo: logoC}}/>
            </div>
        </div>
    );
}

export default App;
